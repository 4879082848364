.loading-spinner-container {
  position: fixed;
  left: 50%;
  top: 35%;
  z-index: 1052;
}

.loading-background {
  position: fixed;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;

  z-index: 1051;
  background-color: #000;
  opacity: 0.3;
}

.spinner-bg {
  position: relative;
  left: -50%;
}
