@import url("https://fonts.googleapis.com/css2?family=Lato&family=Montserrat&family=Open+Sans&family=Roboto&display=swap");

body {
  margin: 0;
  padding: 0;

  font-family: "Roboto", sans-serif;
  background-color: #34495e !important;
}

h1 {
  font-family: "Lato", sans-serif;
}

.container {
  background: #fff;
  margin: 80px auto;
  padding: 10px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}

@media print {
  * {
    -webkit-print-color-adjust: exact;
  }

  body {
    margin: 0;
    padding: 0;

    background-color: initial !important;
  }
}
